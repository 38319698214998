<div class="modal-header">
  <h4 class="modal-title">{{ ueberschriftTranslationKey | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span>
    {{
      beschreibungsVariablen
        ? (beschreibungTranslationKey | translate: beschreibungsVariablen)
        : (beschreibungTranslationKey | translate)
    }}
  </span>
  <div *ngIf="hinweisTranslationKey">
    <small class="text-muted">
      {{ hinweisTranslationKey | translate }}
    </small>
  </div>
</div>
<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn" [ngClass]="secondaryButtonClass" (click)="decline()">
      {{
        ablehnenButtonTranslationKey ? (ablehnenButtonTranslationKey | translate) : ('ALLGEMEIN.ABBRECHEN' | translate)
      }}
    </button>
    <button type="submit" class="btn" [ngClass]="buttonClass" (click)="accept()">
      {{
        annehmenButtonTranslationKey
          ? (annehmenButtonTranslationKey | translate)
          : ('ALLGEMEIN.UEBERNEHMEN' | translate)
      }}
    </button>
  </div>
</div>
