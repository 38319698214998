/**
 * Describes a section containg a overview and details pages
 */
interface Section {
  OVERVIEW?: TableConfig;
  DETAILS?: TableConfig;
}

/**
 * Describses the weight list
 */
interface Wiegeliste {
  GENERAL?: TableConfig;
  IXP?: TableConfig;
  MFA?: TableConfig;
}

/**
 * Describes the possible table configurations
 */
interface TableConfig {
  CATEGORIES?: string;
  COLUMNS?: string;
  GROUP?: string;
  PAGINATION?: string;
  SORT?: string;
}

/**
 * Defines the constans from which data will be get/set from/to the localstorage.
 */
export class LOCALSTORAGE {
  public static readonly PREFIX = 'iqportal';

  public static readonly CURRENT_USER_ID = 'currentUserId';
  public static readonly AUTHORIZATION_DATA = 'authorizationData';
  public static readonly MUST_CHANGE_PASSWORD = 'mustChangePassword';
  public static readonly MUST_VALIDATE_EMAIL = 'mustValidateEmail';
  public static readonly VERGLEICHS_SCHLACHTBETRIEB_ID = 'vergleichsSchlachtbetriebId';
  public static readonly LIEFERPARTIEN_DETAILS_BEFUNDE_OPEN = 'schlachtdaten.lieferpartien.details.befunde.open';
  public static readonly SALMONELLEN_DETAILS_UEBERSICHT_ZEITRAUM = 'salmonellen.details.uebersicht.zeitraum';
  public static readonly LANG = 'lang';
  public static readonly ITW_CHANGES_MODAL_HIDE = 'itw.changes.modal.hide';

  public static readonly FILTER = {
    GLOBAL: {
      BETRIEBSSTAETTEN_FILTER_SELECTION: 'filter.global.betriebsstaettenFilterSelection',
    },
    UEBERGEORDNET: {
      SO: 'filter.uebergeordnet.so',
      ETM: 'filter.uebergeordnet.etm',
      ETZ: 'filter.uebergeordnet.etz',
    },
    SEGMENT: {
      SO: 'filter.segment.so',
      ETM: 'filter.segment.etm',
      ETZ: 'filter.segment.etz',
    },
    SCHLACHTDATEN: {
      LIEFERPARTIEN: {
        ZEITRAUM: 'filter.schlachtdaten.lieferpartien.zeitraum',
      },
    },
    BLACKBOX: {
      LIEFERPARTIEN: {
        ZEITRAUM: 'filter.blackbox.lieferpartien.zeitraum',
      },
    },
  };

  public static readonly MODAL = {
    EMAIL_VALIDIEREN: 'modal.email.validieren',
  };

  public static readonly SCHLACHTDATEN = {
    AGGREGATION: {
      GEWICHT: {
        INTERVALL: 'schlachtdaten.aggregation.gewicht.intervall',
        AXES: {
          IXP: 'schlachtdaten.aggregation.gewicht.axes.ixp',
          MFA: 'schlachtdaten.aggregation.gewicht.axes.mfa',
        },
        OPTIMALBEREICH: {
          IXP: 'schlachtdaten.aggregation.gewicht.optimalbereich.ixp',
          MFA: 'schlachtdaten.aggregation.gewicht.optimalbereich.mfa',
        },
        TABLE: {
          IXP: 'schlachtdaten.aggregation.ixp.spalten',
          MFA: 'schlachtdaten.aggregation.mfa.spalten',
        },
      },
      ZEITRAUM: {
        INTERVALL: 'schlachtdaten.aggregation.zeitraum.intervall',
        AXES: {
          IXP: 'schlachtdaten.aggregation.zeitraum.axes.ixp',
          MFA: 'schlachtdaten.aggregation.zeitraum.axes.mfa',
        },
        TABLE: {
          IXP: 'schlachtdaten.aggregation.ixp.spalten',
          MFA: 'schlachtdaten.aggregation.mfa.spalten',
        },
      },
      MFA_CLASSIFICATION: {
        CLASSIFICATION: 'schlachtdaten.aggregation.mfa_classification',
      },
      VERGLEICHSWERTE_AUSBLENDEN: 'schlachtdaten.aggregation.vergleichswerteAusblenden',
    },
    BASISVERGLEICH: {
      IXP: 'schlachtdaten.basisvergleich.ixp.diagrammValues',
      MFA: 'schlachtdaten.basisvergleich.mfa.diagrammValues',
      VERGLEICHSWERTE_AUSBLENDEN: 'schlachtdaten.basisvergleich.vergleichswerteAusblenden',
      MFA_CLASSIFICATION: 'schlachtdaten.basisvergleich.mfa_classification',
    },
    BETRIEBSVERGLEICH: {
      IXP: {
        COLUMNS: 'schlachtdaten.betriebsvergleich.uebersicht.ixp.spalten',
        SORT: 'schlachtdaten.betriebsvergleich.uebersicht.ixp.sort',
      },
      MFA: {
        COLUMNS: 'schlachtdaten.betriebsvergleich.uebersicht.mfa.spalten',
        SORT: 'schlachtdaten.betriebsvergleich.uebersicht.mfa.sort',
        CLASSIFICATION: 'schlachtdaten.betriebsvergleich.uebersicht.mfa_classification'
      },
      AXES: {
        IXP: 'schlachtdaten.betriebsvergleich.axes.ixp',
        MFA: 'schlachtdaten.betriebsvergleich.axes.mfa',
      },
      SELECTED_BETRIEBSTAETTEN: {
        IXP: 'schlachtdaten.betriebsvergleich.selektierteBetriebsstaetten.ixp',
        MFA: 'schlachtdaten.betriebsvergleich.selektierteBetriebsstaetten.mfa',
      },
      MINDEST_ANZAHL_TIERE: {
        IXP: 'schlachtdaten.betriebsvergleich.mindestAnzahlTiere.ixp',
        MFA: 'schlachtdaten.betriebsvergleich.mindestAnzahlTiere.mfa',
      },
    },
    BEFUNDDATEN: {
      OVERVIEW: {
        CATEGORIES: 'schlachtdaten.befunddaten.uebersicht.kategorien',
        COLUMNS: 'schlachtdaten.befunddaten.uebersicht.spalten',
        SORT: 'schlachtdaten.befunddaten.uebersicht.sort',
        VVVO: 'schlachtdaten.befunddaten.vvvo',
      },
      KUPIERVERZICHT: {
        CATEGORIES: 'schlachtdaten.befunddaten.kupierverzicht.kategorien',
        COLUMNS: 'schlachtdaten.befunddaten.kupierverzicht.spalten',
        SORT: 'schlachtdaten.befunddaten.kupierverzicht.sort',
        VVVO: 'schlachtdaten.befunddaten.vvvo',
      },
    },
    LIEFERPARTIEN: <Section>{
      OVERVIEW: {
        COLUMNS: 'schlachtdaten.lieferpartien.uebersicht.spalten',
        CATEGORIES: 'schlachtdaten.lieferpartien.uebersicht.kategorien',
        SORT: 'schlachtdaten.lieferpartien.uebersicht.sort',
        GROUP: 'schlachtdaten.lieferpartien.uebersicht.group',
        PAGINATION: 'schlachtdaten.lieferpartien.uebersicht.pagination',
      },
    },
    LIEFERZEITRAUM: {
      UEBERSICHT: {
        OPTIMALBEREICH: {
          IXP: 'schlachtdaten.lieferzeitraum.uebersicht.optimalbereich.ixp',
          MFA: 'schlachtdaten.lieferzeitraum.uebersicht.optimalbereich.mfa',
        },
        MFA_CLASSIFICATION: 'schlachtdaten.lieferzeitraum.uebersicht.mfa_classification',
        VERGLEICHSWERTE_AUSBLENDEN: 'schlachtdaten.lieferzeitraum.uebersicht.vergleichswerteAusblenden',
      },
    },
    WIEGELISTE: <Wiegeliste>{
      GENERAL: {
        COLUMNS: 'schlachtdaten.wiegeliste.general.spalten',
        CATEGORIES: 'schlachtdaten.wiegeliste.general.kategorien',
        PAGINATION: 'schlachtdaten.wiegeliste.general.pagination',
      },
      IXP: {
        SORT: 'schlachtdaten.wiegeliste.ixp.sort',
      },
      MFA: {
        SORT: 'schlachtdaten.wiegeliste.mfa.sort',
      },
    },
  };

  public static readonly BLACKBOX = {
    OVERVIEW: {
      COLUMNS: 'blackbox.uebersicht.spalten',
      CATEGORIES: 'blackbox.uebersicht.kategorien',
      SORT: 'blackbox.uebersicht.sort',
      GROUP: 'blackbox.uebersicht.group',
      PAGINATION: 'blackbox.uebersicht.pagination',
    },
    DETAILS: {
      WIEGELISTE: {
        SORT: 'blackbox.details.wiegeliste.sort',
      },
    },
  };

  public static readonly ETM = {
    BASISVERGLEICH: {
      VERGLEICHSWERTE_AUSBLENDEN: 'etm.basisvergleich.vergleichswerteAusblenden',
    },
    EINZELTIERLISTE: {
      COLUMNS: 'etm.einzeltierliste.spalten',
      CATEGORIES: 'etm.einzeltierliste.kategorien',
      PAGINATION: 'etm.einzeltierliste.pagination',
      SORT: 'etm.einzeltierliste.sort',
    },
  };

  public static readonly ETZ = {
    BASISVERGLEICH: {
      VERGLEICHSWERTE_AUSBLENDEN: 'etz.basisvergleich.vergleichswerteAusblenden',
    },
    EINZELTIERLISTE: {
      COLUMNS: 'etz.einzeltierliste.spalten',
      CATEGORIES: 'etz.einzeltierliste.kategorien',
      PAGINATION: 'etz.einzeltierliste.pagination',
      SORT: 'etz.einzeltierliste.sort',
    },
    RANGIERUNG: {
      COLUMNS: 'etz.rangierung.spalten',
      CATEGORIES: 'etz.rangierung.kategorien',
      PAGINATION: 'etz.rangierung.pagination',
      SORT: 'etz.rangierung.sort',
    },
  };

  public static readonly QS_MANAGEMENT = {
    BETRIEBSLISTE: <TableConfig>{
      COLUMNS: 'qs-management.betriebsliste.spalten',
      CATEGORIES: 'qs-management.betriebsliste.kategorien',
      SORT: 'qs-management.betriebsliste.sort',
      GROUP: 'qs-management.betriebsliste.group',
      PAGINATION: 'qs-management.betriebsliste.pagination',
    },
    MAENGELLISTE: <TableConfig>{
      COLUMNS: 'qs-management.maengelliste.spalten',
      CATEGORIES: 'qs-management.maengelliste.kategorien',
      SORT: 'qs-management.maengelliste.sort',
      GROUP: 'qs-management.maengelliste.group',
      PAGINATION: 'qs-management.maengelliste.pagination',
    },
  };

  public static readonly SALMONELLEN: Section = {
    OVERVIEW: {
      COLUMNS: 'salmonellen.uebersicht.spalten',
      CATEGORIES: 'salmonellen.uebersicht.kategorien',
      SORT: 'salmonellen.uebersicht.sort',
      GROUP: 'salmonellen.uebersicht.group',
      PAGINATION: 'salmonellen.uebersicht.pagination',
    },
  };

  public static readonly ANTIBIOTIKA: Section = {
    OVERVIEW: {
      COLUMNS: 'antibiotika.uebersicht.spalten',
      CATEGORIES: 'antibiotika.uebersicht.kategorien',
      SORT: 'antibiotika.uebersicht.sort',
      GROUP: 'antibiotika.uebersicht.group',
      PAGINATION: 'antibiotika.uebersicht.pagination',
    },
  };

  public static readonly QUALITAETSSICHERUNG: Section = {
    OVERVIEW: {
      COLUMNS: 'qualitaetssicherung.uebersicht.spalten',
      CATEGORIES: 'qualitaetssicherung.uebersicht.kategorien',
      SORT: 'qualitaetssicherung.uebersicht.sort',
      GROUP: 'qualitaetssicherung.uebersicht.group',
      PAGINATION: 'qualitaetssicherung.uebersicht.pagination',
    },
  };

  public static readonly BETRIEBSSTAETTEN: Section = {
    OVERVIEW: {
      COLUMNS: 'betriebsstaetten.uebersicht.spalten',
      CATEGORIES: 'betriebsstaetten.uebersicht.kategorien',
      SORT: 'betriebsstaetten.uebersicht.sort',
      GROUP: 'betriebsstaetten.uebersicht.group',
      PAGINATION: 'betriebsstaetten.uebersicht.pagination',
    },
  };

  public static readonly UNTERBERATER: TableConfig = {
    COLUMNS: 'unterberater.spalten',
    CATEGORIES: 'unterberater.kategorien',
    SORT: 'unterberater.sort',
    GROUP: 'unterberater.group',
    PAGINATION: 'unterberater.pagination',
  };

  public static readonly ITW: Section = {
    OVERVIEW: {
      COLUMNS: 'itw.uebersicht.spalten',
      CATEGORIES: 'itw.uebersicht.kategorien',
      SORT: 'itw.uebersicht.sort',
      GROUP: 'itw.uebersicht.group',
      PAGINATION: 'itw.uebersicht.pagination',
    },
  };

  public static readonly MASTGRUPPEN = {
    OVERVIEW: {
      COLUMNS: 'mastgruppen.uebersicht.spalten',
      CATEGORIES: 'mastgruppen.uebersicht.kategorien',
      SORT: 'mastgruppen.uebersicht.sort',
      GROUP: 'mastgruppen.uebersicht.group',
      PAGINATION: 'mastgruppen.uebersicht.pagination',
    },
    DETAILS: {
      LIEFERPARTIEN: {
        COLUMNS: 'mastgruppen.details.lieferpartien.spalten',
        CATEGORIES: 'mastgruppen.details.lieferpartien.kategorien',
        SORT: 'mastgruppen.details.lieferpartien.sort',
      },
    },
  };

  public static readonly FOODTEMPLATES = {
    OVERVIEW: {
      COLUMNS: 'foodtemplates.overview.columns',
      SORT: 'foodtemplates.overview.sort',
      GROUP: 'foodtemplates.overview.group',
    },
  };

  public static readonly BENACHRICHTIGUNGEN = {
    OVERVIEW: {
      COLUMNS: 'benachrichtigung.uebersicht.spalten',
      CATEGORIES: 'benachrichtigung.uebersicht.kategorien',
      SORT: 'benachrichtigung.uebersicht.sort',
      GROUP: 'benachrichtigung.uebersicht.group',
      PAGINATION: 'benachrichtigung.uebersicht.pagination',
    },
  };

  public static readonly VERTRAGSVERWALTUNG = {
    OVERVIEW: {
      COLUMNS: 'vertragsverwaltung.uebersicht.spalten',
      CATEGORIES: 'vertragsverwaltung.uebersicht.kategorien',
      SORT: 'vertragsverwaltung.uebersicht.sort',
      GROUP: 'vertragsverwaltung.uebersicht.group',
      PAGINATION: 'vertragsverwaltung.uebersicht.pagination'
    },
    SHOW_CANCELED: 'vertragsverwaltung.show_canceled'
  };

  public static readonly LOYALTY_BONUS = {
    OVERVIEW: {
      COLUMNS: 'treuebonus.uebersicht.spalten',
      CATEGORIES: 'treuebonus.uebersicht.kategorien',
      SORT: 'treuebonus.uebersicht.sort',
      GROUP: 'treuebonus.uebersicht.group',
      PAGINATION: 'treuebonus.uebersicht.pagination'
    },
    SHOW_CANCELED: 'treuebonus.show_canceled'
  };

  public static readonly REMEDYING_DEFECTS = {
    OVERVIEW: {
      VVVO: 'remedying_defects.overview.vvvo',
    },
  };

  public static readonly ZERO_REPORTS = {
    OVERVIEW: {
      VVVO: 'zero_reports.overview.vvvo',
    },
  };

  public static readonly BESTANDREGISTER = {
    OVERVIEW: {
      COLUMNS: 'bestandregister.uebersicht.spalten',
      SORT: 'bestandregister.uebersicht.sort',
    },
    DETAILS: {
      COLUMNS: 'bestandregister.details.spalten',
      SORT: 'bestandregister.details.sort',
      PAGINATION: 'bestandregister.details.pagination',
    }
  };
}
